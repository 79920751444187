import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import upperFirst from 'lodash/upperFirst';

import styles from './CardData.module.scss';

const CardData = React.memo(
  ({ boardCardCount, gitData, createdAt, currentBoard, name, canEdit, labels }) => {
    const isCardBug = labels.some((l) => l.name.toLowerCase() === 'bug');

    let diffInDays = 0;
    let colorBugDays = 'default';
    if (isCardBug) {
      const today = new Date().toISOString().slice(0, 10);
      const cardDate = new Date(createdAt.slice(0, 10)); // '2023-05-08T18:28:06.000Z';

      const diffInMs = new Date(today) - new Date(cardDate);
      diffInDays = diffInMs / (1000 * 60 * 60 * 24);

      if (diffInDays > 7) {
        colorBugDays = 'red';
      } else if (diffInDays > 5) {
        colorBugDays = 'yellow';
      }
    }

    let gitBranchName = '';
    let gitPrName = '';
    let gitPrState = '';
    let gitPrId = 0;
    let gitPrColor = 'grey';
    let gitBranchColor = 'grey';

    if (gitData != null) {
      // branch
      if (gitData.branch != null) {
        gitBranchName = gitData.branch;
        gitBranchColor = 'teal';
      }

      // pull request
      if (gitData.pullRequest != null) {
        gitPrId = gitData.pullRequest.id;
        gitPrName = gitData.pullRequest.name;
        gitPrState = gitData.pullRequest.state;
        if (gitPrState === 'open') {
          gitPrColor = 'blue';
        } else if (gitPrState === 'merged') {
          gitPrColor = 'green';
        } else if (gitPrState === 'declined') {
          gitPrColor = 'red';
        } else {
          gitPrColor = 'black';
        }
      }
    }

    const contentNode = canEdit && (
      <div className={styles.actionsCardId}>
        <div>
          <span className={classNames(styles.wrapper, styles.actionsTitleCard)}>
            <Icon name="hashtag" />
            {boardCardCount}
          </span>
          {isCardBug && diffInDays > 5 && (
            <span
              className={classNames(
                styles.wrapper,
                styles.wrapperExtra,
                styles[`wrapper${upperFirst('small')}`],
                styles[`git${upperFirst(colorBugDays)}`],
              )}
              title={`Bug open for ${diffInDays} days`}
            >
              <Icon name="warning sign" size="small" />
              {diffInDays}
            </span>
          )}
        </div>
        {currentBoard.devBoard && (
          <div>
            {/* TODO: debug test - to delete! */}
            {/* <p>gitBranchName: {gitBranchName}</p> */}
            {/* <p>gitPrName: {gitPrName}</p> */}
            {/* <p>gitPrId: {gitPrId}</p> */}
            {/* <p>gitPrState: {gitPrState}</p> */}
            {/* <p>createdAt: {createdAt}</p> */}
            {/* <p>currentBoard: {JSON.stringify(currentBoard)}</p> */}
            {/* <p>currentBoard.devBoard: {String(currentBoard.devBoard)}</p> */}
            {/* <p>currentBoard.gitProject: {currentBoard.gitProject}</p> */}
            {/* <p>labels: {String(labels)}</p> */}
            {/* <p>labels: {JSON.stringify(labels)}</p> */}
            {/* <p>cardBug: {String(isCardBug)}</p> */}
            {/* <p>gitData: {JSON.stringify(gitData)}</p> */}
            {/* TODO: debug test - to delete! */}

            <Button
              fluid
              color={gitBranchColor}
              className={styles.actionButtonNoColor}
              onClick={() =>
                window.open(
                  `${
                    gitBranchName === ''
                      ? `https://bitbucket.org/${
                          currentBoard.gitProject
                        }/branch?issueKey=${boardCardCount}/${name}&issueType=${
                          isCardBug ? 'Bug' : 'Feature'
                        }`
                      : `https://bitbucket.org/${currentBoard.gitProject}/branch/${gitBranchName}`
                  }`,
                  '_blank',
                )
              }
            >
              <Icon name="code branch" className={styles.actionIconNoColor} />
              {gitBranchName === '' ? 'Create Branch' : 'View Branch'}
            </Button>
            {(gitBranchName !== '' || gitPrName !== '') && (
              <Button
                fluid
                color={gitPrColor}
                className={styles.actionButtonNoColor}
                onClick={() =>
                  window.open(
                    `${
                      gitPrName === ''
                        ? `https://bitbucket.org/${currentBoard.gitProject}/pull-requests/new?source=${gitBranchName}&event_source=branch_list`
                        : `https://bitbucket.org/${currentBoard.gitProject}/pull-requests/${gitPrId}`
                    }`,
                    '_blank',
                  )
                }
              >
                <Icon name="hand paper outline" className={styles.actionIconNoColor} />
                {gitPrName === '' ? 'Create Pull Request' : 'View Pull Request'}
              </Button>
            )}
          </div>
        )}
      </div>
    );

    return contentNode;
  },
);

CardData.propTypes = {
  boardCardCount: PropTypes.number.isRequired,
  gitData: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  createdAt: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  currentBoard: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  canEdit: PropTypes.bool.isRequired,
  labels: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
};

CardData.defaultProps = {
  gitData: undefined,
};

export default CardData;
