import upperFirst from 'lodash/upperFirst';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from 'semantic-ui-react';

import styles from './CardId.module.scss';

const SIZES = {
  TINY: 'tiny',
  SMALL: 'small',
  MEDIUM: 'medium',
};

const getGitColor = (gitData) => {
  let color = 'default';

  if (gitData != null) {
    if (gitData.pullRequest != null) {
      if (gitData.pullRequest.state !== null) {
        if (gitData.pullRequest.state === 'open') {
          color = 'blue';
        } else if (gitData.pullRequest.state === 'merged') {
          color = 'green';
        } else if (gitData.pullRequest.state === 'declined') {
          color = 'red';
        } else {
          color = 'black';
        }
      }
    } else {
      color = 'teal';
    }
  }

  return color;
};

const CardId = React.memo(({ value, size, gitData, createdAt, labels }) => {
  const color = getGitColor(gitData);

  const isCardBug = labels.some((l) => l.name.toLowerCase() === 'bug');

  let diffInDays = 0;
  let colorBugDays = 'default';
  if (isCardBug) {
    const today = new Date().toISOString().slice(0, 10);
    const cardDate = new Date(createdAt.slice(0, 10)); // '2023-05-08T18:28:06.000Z';

    const diffInMs = new Date(today) - new Date(cardDate);
    diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    if (diffInDays > 7) {
      colorBugDays = 'red';
    } else if (diffInDays > 5) {
      colorBugDays = 'yellow';
    }
  }

  return (
    <span>
      <span
        className={classNames(
          styles.wrapper,
          styles[`wrapper${upperFirst(size)}`],
          styles[`git${upperFirst(color)}`],
        )}
      >
        <Icon name="hashtag" size="small" />
        {value}
      </span>
      {isCardBug && diffInDays > 5 && (
        <span
          className={classNames(
            styles.wrapper,
            styles[`wrapper${upperFirst(size)}`],
            styles[`git${upperFirst(colorBugDays)}`],
          )}
          title={`Bug open for ${diffInDays} days`}
        >
          <Icon name="warning sign" size="small" />
          {diffInDays}
        </span>
      )}
    </span>
  );
});

CardId.propTypes = {
  value: PropTypes.number.isRequired,
  size: PropTypes.oneOf(Object.values(SIZES)),
  gitData: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  createdAt: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types */
  labels: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
};

CardId.defaultProps = {
  size: SIZES.MEDIUM,
  gitData: undefined,
};

export default CardId;
